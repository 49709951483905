@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: horley-old-style;
  src: url('/fonts/HorleyOldStyleMTStd.ttf') format('truetype');
}

@font-face {
  font-family: georgia;
  src: url('/fonts/georgia.ttf') format('truetype');
}

@font-face {
  font-family: hoefler-text;
  src: url('/fonts/hoefler-text.ttf') format('truetype');
}

@font-face {
  font-family: 'hoefler-text-black';
  src: url('/fonts/hoefler-text-black.ttf') format('truetype');
}

@font-face {
  font-family: minion-pro;
  src: url('/fonts/minion-pro.ttf') format('truetype');
}

.slider {
  display: flex;
}

body {
  min-width: 347px;
  overflow-x: hidden;
}

@media (max-width: 380px) {
  .xs\:svg-height {
    max-height: 192px !important;
    min-width: auto !important;
    width: 100% !important;
  }

  .xs\:h1 {
    font-size: 32px;
  }
}
